import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { graphql } from "gatsby";
import { notification, Button } from "antd";

import seoData from "../../components/SEO/data";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import Pages from "../../components/Pages";
import { ContentBlock } from "../../components/Blocks";
import { TextBlock } from "../../components/Blocks";
import { ContentList } from "../../components/Lists";
import { formatURL, removeEmptyKeys, removePreloader } from "../../helpers";
import { Share } from "../../widgets";
import { showMore } from "../../constants";
import { useThemeContext } from "../../components/Layout/ThemeContext";

import { CITY_OBJECTS, CITY_OBJECTS_AGGREGATE } from "../../queries/queries.graphql";

export const query = graphql`
	query CityObjectsPage($slug: String!, $pagePath: String!)  {
		hasura {
			...CityObjects
			...PageSettings
		}
	}
`;

export default function CityObjects({ data, pageContext }) {
	const title = "Достопримечательности";
	const { theme } = useThemeContext();
	const { slug, title_full } = pageContext;
	const limit = 4;
	const initialCityObjects = get(data, "hasura.cities[0].city_objects", []);
	const url = `${slug}/city-objects`;
	const entity = data?.hasura?.page_settings?.[0] || get(seoData, "city_objects__page", {});
	const breadcrumbsContext = {
		item: {
			slug,
			title_full,
		},
	};

	const [offset, setOffset] = useState(null);

	const {
		loading: aggregateLoading,
		error: aggregateError,
		data: cityObjectsAggregate,
	} = useQuery(CITY_OBJECTS_AGGREGATE, {
		variables: {
			slug,
		},
	});

	const count = get(cityObjectsAggregate, "cities[0].city_objects_aggregate.aggregate.count", 0);

	const makeData = useCallback(data => data.map(({ title_short, title_full, slug, main_image_preview, main_image }) => {
		const image = get(main_image_preview, "src", "") || get(main_image, "src", "");

		return {
			slug: formatURL(`${get(pageContext, "slug", "")}/${slug}`),
			image,
			title: title_short || title_full,
		};
	}), [formatURL]);

	const [cityObjects, setCityObjects] = useState(makeData(initialCityObjects));

	const [loadCityObjects, { called, loading, error, data: cityObjectsData }] = useLazyQuery(
		CITY_OBJECTS,
		{
			variables: {
				slug,
				limit,
			},
			partialRefetch: true,
		});

	useEffect(() => {
		if (called && !error && !loading) {
			setCityObjects(cityObjects => {
				const newStateBlank = [...cityObjects, ...makeData(get(cityObjectsData, "cities[0].city_objects", []))];

				setOffset(newStateBlank.length >= count ? null : offset + limit);

				return newStateBlank;
			});
		} else if (error) {
			setOffset(null);
		}

		removePreloader(!loading || error);
	}, [loading, cityObjectsData, called]);

	useEffect(() => {
		if (!aggregateError && initialCityObjects.length < count) {
			setOffset(limit);
		}

		if (aggregateError) {
			console.error("aggregate Error on ContentNews");
			notification.error({
				message: "Ошибка загрузки данных",
			});
		}
	}, [count, aggregateError]);

	const onLoadMore = useCallback(() => loadCityObjects({
		variables: {
			offset,
		},
	}), [offset]);

	return (
		<Pages
			entity={entity}
			url={url}
		>
			<ContentBlock>
				<div className="container">
					<Breadcrumbs currentLabel={title} pageContext={breadcrumbsContext} />
				</div>
			</ContentBlock>
			<ContentBlock>
				<div className={"container"}>
					<TextBlock title={title}>
						<ContentList
							items={cityObjects}
							className={"col-12 col-md-3"}
							style={{
								width: "100%",
								height: "400px",
								objectFit: "cover",
							}}/>
					</TextBlock>
					<div className="row justify-content-center mb-5">
						<Button
							type={"primary"}
							disabled={loading || !offset || aggregateLoading}
							loading={loading}
							onClick={onLoadMore}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
						>
							{showMore}
						</Button>
					</div>
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleShort={title} />
			</div>
		</Pages>
	);
}

CityObjects.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
};

CityObjects.defaultProps = {
	data: {},
	pageContext: {
		slug: "",
		title_full: "",
	},
};
